import React from 'react';
import ReactDOM from 'react-dom/client';
import './Styles/index.scss';
import { store } from './Redux'
import { Provider } from 'react-redux'
import App from 'Screens/App';
import "Utils/Languages/i18n";
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
      <App />
    </Provider>
);