import { useState } from 'react';
import * as Icons from 'react-bootstrap-icons';
import MenuDrawer from './MenuDrawer';
import LanguageDrawer from './LanguageDrawer';
export default function MenuSmall() {
    const [open, setOpen] = useState(false);
    return (
        <>
            <div className='menu-icon' onClick={() => setOpen(true)}>
                <p>Menu</p><Icons.List />
            </div>
            {
                open && 
                <div className='menu-back'>
                    <div className='back' onClick={() => setOpen(false)}/>
                    <div className={`menu-list ${open ? 'show' : 'hide'}-mode`}>
                        <MenuDrawer onClick={() => setOpen(false)} />
                        <LanguageDrawer onClick={() => setOpen(false)} />
                    </div>
                </div>
            }
        </>
    );
}