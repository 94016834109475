import React, { useEffect } from 'react';
import { Outlet } from "react-router-dom";
import Menu from 'Screens/Menu/Menu';
import FooterLanguage from './FooterLanguage';
import Loading from './Loading';
import { firstinit } from 'Utils/Helpers/ApiServices';
import { useDispatch } from 'react-redux';
export default function Layout() {
    const dispatch = useDispatch();
    useEffect(() => { dispatch(firstinit()); },[])
    return (
        <div className='app-home' id='maindiv'>
            <div className='rect-home-page' />
            <Loading />
            <div className='main-paper' id='mainpaper' key={'main-paper'}>
                <Menu />
                <div className='paper-body' id='paperbody' key={'paper-body'}>
                    <Outlet />
                </div>
            </div>
            <FooterLanguage />
        </div>
    );
}