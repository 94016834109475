import * as Icons from 'react-bootstrap-icons'
import { useDispatch } from 'react-redux';
import { LanguageMenu } from 'Utils/Constants/LanguageMenu';
import { useTranslation } from "react-i18next";
import { changeLanguage } from 'Utils/Helpers/ApiServices';
export default function LanguageDrawer({ onClick }) {
    const dispatch = useDispatch();
    const {i18n} = useTranslation();
    const onChange= (lang) => {
        dispatch(changeLanguage(lang));
        setTimeout(() => {
            onClick();
        }, 1000);
    }
    return (
        <div className='languages-menu'>
            <Icons.Translate size={18} color='white'/>
            {
                LanguageMenu.map((i, index) => {
                    return (
                        <p key={'menu-language' + index} onClick={() => onChange(i.action)} className={i.action === i18n.language ? 'active' : ''} >
                            {i.title}
                        </p>
                    )
                })
            }
        </div>
    );
}