export const frTexts = {
    welcome: "Au Cœur de la [Galaxie]",
    welcomeText: "Dans la galaxie, au point d'origine, où les secrets divins sont cachés et où réside la magnitude absolue d'une grande énergie, une porte s'ouvrit. Cette porte, aussi vaste que la grandeur divine, avait sept portes. Ces portes, façonnées à partir de rêves, d'amour et d'unicité, gardaient l'entrée d'un royaume habité par des créatures extraordinaires, débordantes d'intelligence et de talent étonnants. Chaque créature, plus intelligente que la précédente, possédait des couleurs, des visages et des dons divins uniques, pleins d'excitation et de vitalité. Elles manifestaient une large gamme d'émotions, de la colère au calme, des larmes au rire, toutes contrôlées dans leurs esprits, les rendant juges disciplinés.",
    homeSecondParaTitle: 'Notre [Histoire]',
    homeSecondParaText: 'Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression.',

    charactersPageTitle: 'Mes [Personnages]',
    charactersPageText: "Chaque légende que vous voyez ici est un témoignage des royaumes infinis de l'imagination, méticuleusement dessinée à la main avec cœur et âme, capturant les murmures d'un univers au-delà du nôtre. Ces œuvres d'art ne sont pas simplement des créations; elles sont des histoires vivantes, façonnées à la main pour apporter le mystique et l'extraordinaire dans notre monde.",
    charactersCodeShow: 'Code des caractères [@P1]',
    charactersCodeText: 'Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression.',
    otherShapeOfCharacter: "d'autres [personnages]",
    character_1_description: "La reine est un être d'une beauté unique, doté d'yeux extraordinaires qui voient au-delà de l'ordinaire. Son regard est rempli d'amour, mais lorsque la justice l'exige, ses yeux deviennent enflammés, rétablissant l'équilibre d'un simple coup d'œil. Ses cils apportent le parfum du printemps et de la vitalité, une essence divine partout où elle se trouve. Ce parfum céleste est différent de tout ce que l'on a jamais expérimenté. Ses oreilles peuvent entendre même les murmures les plus faibles de la galaxie, les traitant à travers six couches avant qu'elle ne décide de parler. Son esprit transcende le temps, comprenant la nécessité d'une vie pleine de sens. Son corps, fait de soie divine pure, porte des pouvoirs qu'elle utilise avec sagesse et grâce.",
    character_2_description: "En raison de ses capacités très élevées, il est chargé de la communication avec les forces créatives des autres planètes.@BRAvec sa bouche et les symboles sur son visage, il établit des connexions avec d'autres planètes. Grâce à ses oreilles, il écoute leurs messages et évalue si ces autres planètes ont la capacité de communiquer avec la planète Tamima. Si c'est le cas, une connexion avec Tamima est établie, utilisant leur créativité au profit de Tamima.@BRDe plus, grâce à ses symboles remarquables et à l'énergie élevée présente dans son corps, il peut presque contrôler la rotation de la planète Tamima.",
    character_3_description: "Légendes Bleues",

    tamimaPageTitle: "Le Début d'un Voyage [Mystique]",
    tamimaPageText: "Tout a commencé le jour où je méditais pour apaiser mon esprit et mon corps... J'avais les yeux fermés et je suis tombé dans un rêve merveilleux. Dans ce rêve, j'ai vu une planète peuplée de créatures extraordinaires, chacune me regardant avec des yeux profonds et significatifs, portant un désir non exprimé. Je sentais que mon âme, grâce à la tranquillité que j'avais trouvée, était entrée dans leur monde. Les voir a enflammé en moi une passion ardente pour les représenter à travers mon art. Et j'ai commencé à dessiner...",
    tamimaSecondParaTitle: "Guidé par des Forces [Invisibles]",
    tamimaSecondParaText: "Avec la première ligne tracée sur le papier, mon esprit conscient semblait s'éteindre et mon subconscient a pris le relais. Le temps s'est arrêté ; je n'entendais plus les sons autour de moi et tous mes sens étaient immergés dans un autre monde. En dessinant ces créatures extraordinaires, j'entendais leurs voix dans mon esprit, chacune parlant avec un ton amical et mystérieux, m'exhortant à les amener dans notre monde. Une force puissante s'était connectée à mon cerveau, guidant mes mains. En dessinant, la voix de chaque créature chuchotait dans mon esprit, me guidant sur la manière de capturer leur image de manière splendide. Ils guidaient chaque ligne et courbe, me disant exactement comment ma main devait bouger.",
    tamimaThirdParaTitle: "Création au-delà du [Temps] et de [l'Espace]",
    tamimaThirdParaText: "Le choix des couleurs est devenu une conversation intime ; ils me disaient quelles couleurs les représentaient le mieux. Certains dessins prenaient forme facilement en quelques minutes, tandis que d'autres prenaient des heures, chaque ligne et chaque couleur étant guidées par les voix. Pendant tout ce temps, je travaillais en silence, écoutant uniquement les instructions qui m'étaient données. Dans le processus de dessin, ils décrivaient leur apparence et me disaient quels détails ajouter, y compris les inscriptions sur leurs corps et les numéros utilisés en eux, tout cela à travers les voix dans mon esprit. Après avoir terminé chaque dessin, je ressentais une sensation de fatigue similaire à celle ressentie après des heures de pratique, fatigué mais enthousiaste, car beaucoup d'énergie avait été investie dans chaque œuvre. Quand je regarde mes œuvres, je me retrouve souvent dans la surprise de ne pas me souvenir de certains détails, car ces peintures proviennent entièrement de mon subconscient, sans l'influence de mon esprit conscient. Je suis profondément reconnaissant pour ce don unique – la capacité de représenter ces créatures et de les partager avec le monde. Je suis certain que ces créatures existent quelque part dans la galaxie et sont désireuses d'être vues. Peut-être que dans un avenir proche, nous pourrons voir ces créatures dans le monde réel.",
    
    contactusTitle: '[Contact] nous',
    contactusText: 'par les moyens suivants',
    emailTitle: '[Contact] direct',
    emailPlaceHolder: 'Tapez quelque chose ...',
    emailSend: 'Envoyer',
    successEmail: 'E-mail envoyé avec succès',
    successEmailThank: 'Merci pour votre participation',

    sub_info_4466: "Une créature sans la capacité de parler communique par ses actions. Elle détecte les mensonges grâce aux points jaunes sur son corps et répond en utilisant des faisceaux rouges de son corps. Si elle est en colère, le faisceau devient plus rouge, tandis qu'en état calme, le faisceau s'adoucit.",
    sub_info_3333: "Cette créature a une marque circulaire sur la tête qui absorbe l'énergie négative de la planète et la neutralise.",
    sub_info_2222: "Avec un pouvoir spécial situé dans sa bouche, cet être empêche la croissance excessive des arbres sur la planète Tamima, les élaguant pour maintenir l'équilibre.",
    sub_info_1133: "Si des plantes indésirables tentent de pousser, cette créature utilise ses cils pour les dessécher, les transformant en sol semblable à celui de Tamima.",
    sub_info_3322: "Elle possède un sens de l'odorat remarquable, capable de détecter et de repousser les insectes nuisibles qui tentent d'envahir Tamima depuis d'autres planètes.",
    sub_info_8899: "Avec ses yeux et ses cils, elle fertilise et laboure les terres de Tamima, les préparant à faire pousser un type de plante spéciale qui protège la planète.",
    sub_info_7788: "Son visage, semblable à des oreilles, lui confère une audition excellente. Elle capte même les sons les plus faibles et les envoie à la reine pour analyse en utilisant ses cils. (L'une des capacités de la reine est de comprendre la véritable signification derrière les paroles.)",
    sub_info_6677: "Elle absorbe toute la lumière violette de Tamima, la catégorise à l'aide de ses cils, et distribue la lumière dans les zones nécessitant de l'énergie.",
    sub_info_5566: "Sa tête agit comme un radar, capable d'examiner des rayons ou des lumières étranges. Elle dirige ces rayons vers le côté gauche de la planète, neutralisant les menaces potentielles.",
    sub_info_111: "Son devoir est d'examiner les documents de Tamima, les estampillant pour approbation avec ses cils avant de les envoyer à la reine.",
    sub_info_4444: "Cet être est très conscient et réfléchi sur les questions. Il parle avec sagesse, faisant des pauses prudentes avant de répondre à ce qu'il entend. Il ne parle pas d'un sujet à moins de l'avoir vu lui-même.",
    sub_info_9999: "En raison des chiffres sur son visage, il est responsable de calculer le nombre de météorites et les distances entre les planètes.",
    sub_info_4455: "Avec ses oreilles et ses yeux rouges, il possède une vision et une audition accrues. Il peut détecter les minuscules insectes sous le sol de Tamima et utilise le symbole sur sa tête pour transformer ces insectes en créatures utiles et bénéfiques pour la planète.",
    sub_info_8888: "Cette créature se spécialise dans l'identification de tout étranger tentant d'entrer à Tamima. Elle a la capacité de reconnaître les individus qui ont changé d'apparence.",
    sub_info_7777: "En raison de la forme unique de sa tête et de son front, elle fonctionne comme un puissant appareil photo ou télescope, capable d'observer des distances bien au-delà de Tamima sur des kilomètres.",
    sub_info_3344: "Avec ses multiples yeux et sa grande oreille, elle possède d'excellentes capacités de lecture labiale et d'audition. Elle peut comprendre les langues de toutes les autres planètes en utilisant ses yeux, les analyser avec son oreille, et alerter la reine si un danger est détecté venant d'autres êtres.",
    sub_info_6666: "Il y a cinq points rouges sur son visage et son corps, qui émettent un signal d'alarme si quelqu'un tente de quitter Tamima sans autorisation. Ses cils changent rapidement de forme pour détecter la direction d'où la personne part et l'empêchent de quitter la planète.",
    sub_info_5555: "Sa tête peut se détacher de son corps. Pendant la séparation, à la fois sa tête et son corps restent vivants, lui permettant d'être présente en deux endroits en même temps si nécessaire.",
    sub_info_2233: "Avec les formes géométriques et mathématiques dans ses cils et son corps, elle a le pouvoir de changer certaines maisons sur Tamima qui nécessitent des modifications.",
    sub_info_333: "Avec son expression faciale bienveillante, elle peut transformer la tristesse en joie si elle détecte de la peine chez l'un des habitants de Tamima. Son visage bleu leur apporte la paix.",
    sub_info_1122: "La capacité de cette créature à créer un arc-en-ciel, due à ses yeux dépareillés et à une pièce inhabituelle à l'arrière de sa tête, est vraiment remarquable. En faisant tourner ses yeux, elle forme un arc-en-ciel avec trois couleurs : bleu, jaune et gris. Contrairement à l'arc-en-ciel terrestre, cet arc-en-ciel circulaire rehausse la beauté mystérieuse de Tamima.",
}