import { useDispatch } from 'react-redux';
import { LanguageMenu } from 'Utils/Constants/LanguageMenu';
import { useTranslation } from "react-i18next";
import { changeLanguage } from 'Utils/Helpers/ApiServices';
export default function FooterLanguage() {
    const dispatch = useDispatch();
    const {i18n} = useTranslation();
    const onChange= (lang) => { dispatch(changeLanguage(lang)); }
    return (
        <div className='footer-lang'>
            {
                LanguageMenu.map((i, index) => {
                    return (
                        <p
                            key={'footer-language' + index}
                            onClick={() => onChange(i.action)}
                            className={i.action === i18n.language ? 'active' : ''}
                        >
                            {i.title}
                        </p>
                    )
                })
            }
        </div>
    );
}