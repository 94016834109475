import ImageBox from "Screens/components/ImageBox";
export default function SysLogo({ image, alt }) {
    return (
        <div className='image'>
            <ImageBox
                image={image}
                alt={alt}
            />
        </div>
    );
}