import EN from 'Utils/Assets/Images/Languages/English.svg';
import IT from 'Utils/Assets/Images/Languages/Italy.svg';
import FR from 'Utils/Assets/Images/Languages/France.png';

export const LanguageMenu = [
    {
        id: 1,
        title: "English",
        icon: EN,
        action: 'en',
        active: true,
    },{
        id: 2,
        title: "Italy",
        icon: IT,
        action: 'it',
        active: true,
    },{
        id: 3,
        title: "France",
        icon: FR,
        action: 'fr',
        active: true,
    },
]