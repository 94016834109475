export const itTexts = {
    welcome: "Nel Cuore della [Galassia]",
    welcomeText: "Nella galassia, al punto d'origine, dove i segreti divini sono nascosti e risiede la magnitudine assoluta di una grande energia, si aprì un cancello. Questo cancello, vasto quanto la grandiosità divina, aveva sette porte. Queste porte, forgiate da sogni, amore e unicità, custodivano l'ingresso a un regno abitato da creature straordinarie, traboccanti di intelligenza e talento stupefacenti. Ogni creatura, più intelligente della precedente, possedeva colori, volti e doni divini unici, pieni di eccitazione e vitalità. Mostravano una vasta gamma di emozioni, dalla rabbia alla calma, dalle lacrime al sorriso, tutte controllate all'interno delle loro menti, rendendoli giudici disciplinati.",
    homeSecondParaTitle: 'La nostra [Storia]',
    homeSecondParaText: 'Lorem Ipsum è semplicemente un testo fittizio utilizzato nel settore della tipografia e della stampa. Lorem Ipsum è semplicemente un testo fittizio utilizzato nel settore della tipografia e della stampa.',

    charactersPageTitle: 'I miei [Personaggi]',
    charactersPageText: "Ogni leggenda che vedi qui è una testimonianza dei regni infiniti dell'immaginazione, meticolosamente disegnata a mano con cuore e anima, catturando i sussurri di un universo oltre il nostro. Queste opere d'arte non sono solo creazioni; sono storie viventi, create a mano per portare il mistico e lo straordinario nel nostro mondo.",
    charactersCodeShow: 'Codice dei personaggi [@P1]',
    charactersCodeText: 'Lorem Ipsum è semplicemente un testo fittizio utilizzato nel settore della tipografia e della stampa.',
    otherShapeOfCharacter: 'altri [personaggi]',
    character_1_description: "La regina è un essere di bellezza unica, con occhi straordinari che vedono oltre l'ordinario. Il suo sguardo è pieno d'amore, ma quando la giustizia lo richiede, i suoi occhi si infiammano, ristabilendo l'equilibrio con un semplice sguardo. Le sue ciglia portano il profumo della primavera e della vitalità, un'essenza divina ovunque si trovi. Questo aroma celestiale è diverso da qualsiasi cosa sia mai stata sperimentata. Le sue orecchie possono sentire anche i sussurri più deboli della galassia, elaborandoli attraverso sei strati prima che lei decida di parlare. La sua mente trascende il tempo, comprendendo la necessità di una vita significativa. Il suo corpo, fatto di pura seta divina, porta poteri che usa con saggezza e grazia.",
    character_2_description: "A causa delle sue elevate capacità, è responsabile della comunicazione con le forze creative di altri pianeti.@BRCon la sua bocca e i simboli sul viso, stabilisce connessioni con altri pianeti. Attraverso le sue orecchie, ascolta i loro messaggi e valuta se questi altri pianeti hanno la capacità di comunicare con il pianeta Tamima. Se lo fanno, si stabilisce una connessione con Tamima, utilizzando la loro creatività a beneficio di Tamima.@BRInoltre, grazie ai suoi simboli straordinari e all'alta energia presente nel suo corpo, è in grado di controllare quasi la rotazione del pianeta Tamima.",
    character_3_description: "Leggende Blu",

    tamimaPageTitle: "L’inizio di un Viaggio [Mistico]",
    tamimaPageText: "Tutto iniziò il giorno in cui meditavo per calmare la mia mente e il mio corpo… Avevo gli occhi chiusi e sono caduto in un sogno meraviglioso. In questo sogno, ho visto un pianeta pieno di creature straordinarie, tutte che mi guardavano con occhi profondi e significativi, portando un desiderio non espresso. Sentivo che la mia anima, grazie alla tranquillità che avevo trovato, era entrata nel loro mondo. Vederle ha acceso in me una passione ardente per rappresentarle attraverso la mia arte. E ho iniziato a disegnare…",
    tamimaSecondParaTitle: "Guidato da Forze [Invisibili]",
    tamimaSecondParaText: "Con la prima linea tracciata sulla carta, la mia mente cosciente sembrava spegnersi e il mio subconscio prese il controllo. Il tempo si fermò; non sentivo più i suoni intorno a me e tutti i miei sensi erano immersi in un altro mondo. Mentre disegnavo queste creature straordinarie, sentivo le loro voci nella mia mente, ognuna parlando con un tono amichevole e misterioso, esortandomi a portarli nel nostro mondo. Una forza potente si collegava al mio cervello, guidando le mie mani. Mentre disegnavo, la voce di ogni creatura sussurrava nella mia mente, guidandomi su come catturare la loro immagine in modo splendido. Guidavano ogni linea e curva, dicendomi esattamente come la mia mano doveva muoversi.",
    tamimaThirdParaTitle: "Creazione Oltre il [Tempo] e [lo Spazio]",
    tamimaThirdParaText: "La scelta dei colori diventava una conversazione intima; mi dicevano quali colori li rappresentavano meglio. Alcuni disegni prendevano forma facilmente in pochi minuti, mentre altri richiedevano ore, ogni linea e colore guidati dalle voci. Durante tutto questo tempo, lavoravo in silenzio, ascoltando solo le istruzioni che mi venivano date. Nel processo di disegno, descrivevano il loro aspetto e mi dicevano quali dettagli aggiungere, comprese le iscrizioni sui loro corpi e i numeri utilizzati in essi, tutto attraverso le voci nella mia mente. Dopo aver completato ogni disegno, provavo una sensazione di affaticamento simile a dopo ore di pratica, stanco ma entusiasta, poiché molta energia era stata messa in ogni lavoro. Quando guardo le mie opere, spesso mi trovo a meravigliarmi di alcuni dettagli che non ricordo, poiché questi dipinti provengono interamente dal mio subconscio, senza l’influenza della mia mente cosciente. Sono profondamente grato per questo dono unico – la capacità di rappresentare queste creature e condividerle con il mondo. Sono certo che queste creature esistono da qualche parte nella galassia e sono desiderose di essere viste. Forse nel prossimo futuro, potremo vedere queste creature nel mondo reale.",
    
    contactusTitle: '[Contattaci]',
    contactusText: 'attraverso i seguenti modi',
    emailTitle: '[Contatto] diretto',
    emailPlaceHolder: 'Digita qualcosa ...',
    emailSend: 'Invia',
    successEmail: 'Email inviata correttamente',
    successEmailThank: 'Grazie per la tua partecipazione',

    sub_info_4466: "Una creatura senza la capacità di parlare comunica attraverso le sue azioni. Rileva le bugie attraverso i punti gialli sul suo corpo e risponde utilizzando raggi rossi dal suo corpo. Se è arrabbiata, il raggio diventa più rosso, mentre in stati calmi, il raggio si attenua.",
    sub_info_3333: "Questa creatura ha un segno circolare sulla testa che assorbe l'energia negativa dal pianeta e la neutralizza.",
    sub_info_2222: "Con un potere speciale situato nella bocca, questa creatura impedisce la crescita eccessiva degli alberi sul pianeta Tamima, potandoli per mantenere l'equilibrio.",
    sub_info_1133: "Se qualsiasi pianta indesiderata cerca di crescere, questa creatura usa le sue ciglia per seccarla, trasformandola in terreno simile a quello di Tamima.",
    sub_info_3322: "Possiede un senso dell'olfatto straordinario, in grado di rilevare e respingere gli insetti dannosi che tentano di invadere Tamima da altri pianeti.",
    sub_info_8899: "Con i suoi occhi e le ciglia, fertilizza e ara le terre di Tamima, preparandole per coltivare un tipo speciale di pianta che protegge il pianeta.",
    sub_info_7788: "Il suo volto, che ricorda delle orecchie, le conferisce un udito eccellente. Raccoglie anche i suoni più lievi e li invia alla regina per l'analisi utilizzando le sue ciglia. (Una delle abilità della regina è capire il vero significato dietro le parole.)",
    sub_info_6677: "Assorbe tutta la luce viola di Tamima, la categorizza usando le sue ciglia e distribuisce la luce nelle aree che hanno bisogno di energia.",
    sub_info_5566: "La sua testa funge da radar, capace di esaminare raggi o luci strane. Dirige questi raggi verso il lato sinistro del pianeta, neutralizzando potenziali minacce.",
    sub_info_111: "Il suo compito è esaminare i documenti di Tamima, timbrandoli per l'approvazione con le sue ciglia prima di inviarli alla regina.",
    sub_info_4444: "Questo essere è molto consapevole e riflessivo riguardo alle questioni. Parla con saggezza, facendo pause attente prima di rispondere a ciò che sente. Non parla di un argomento a meno che non l'abbia visto personalmente.",
    sub_info_9999: "A causa dei numeri sul suo viso, è responsabile del calcolo del numero di meteoriti e delle distanze tra i pianeti.",
    sub_info_4455: "Grazie al colore rosso delle sue orecchie e occhi, ha una visione e un udito molto acuti. Può rilevare piccoli insetti sotto il suolo di Tamima e usa il simbolo sulla sua testa per trasformare questi insetti in creature utili e benefiche per il pianeta.",
    sub_info_8888: "Questa creatura si specializza nel riconoscere qualsiasi straniero che tenta di entrare a Tamima. Ha la capacità di riconoscere individui che hanno cambiato il loro aspetto.",
    sub_info_7777: "A causa della forma unica della sua testa e della sua fronte, funziona come una potente macchina fotografica o un telescopio, in grado di osservare distanze molto oltre Tamima per chilometri.",
    sub_info_3344: "Con i suoi occhi multipli e la sua grande orecchia, possiede eccellenti capacità di lettura labiale e udito. Può comprendere le lingue di tutti gli altri pianeti usando i suoi occhi, analizzarle con la sua orecchia, e avvisare la regina se rileva qualsiasi pericolo da altre creature.",
    sub_info_6666: "Ci sono cinque macchie rosse sul suo viso e corpo che emettono un allarme se qualcuno tenta di lasciare Tamima senza permesso. Le sue ciglia cambiano forma rapidamente per rilevare la direzione da cui la persona sta uscendo e impediscono loro di lasciare il pianeta.",
    sub_info_5555: "La sua testa può staccarsi dal corpo. Durante la separazione, sia la testa che il corpo rimangono vivi, permettendole di essere presente in due luoghi contemporaneamente se necessario.",
    sub_info_2233: "Grazie alle forme geometriche e matematiche nelle sue ciglia e nel corpo, ha il potere di cambiare alcune case su Tamima che necessitano di modifiche.",
    sub_info_333: "Con la sua espressione facciale gentile, può trasformare la tristezza in gioia se rileva dolore in uno degli abitanti di Tamima. Il suo viso blu porta loro pace.",
    sub_info_1122: "La capacità di questa creatura di creare un arcobaleno, dovuta ai suoi occhi dissimili e a un pezzo insolito sulla parte posteriore della sua testa, è davvero notevole. Ruotando i suoi occhi, forma un arcobaleno con tre colori: blu, giallo e grigio. A differenza dell'arcobaleno terrestre, questo arcobaleno circolare migliora la bellezza misteriosa di Tamima.",
}