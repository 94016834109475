import ImageBox from "Screens/components/ImageBox";
export default function CharShow({ character, onClick, effect, fixed}) {
    const clicked = () => { if(onClick) onClick(); };
    return (
        <div className={`characters-each ${effect ? 'effect' : ''} ${fixed ? 'fixed' : ''}`} onClick={clicked}>
            <div className='char-image'>
                <ImageBox image={character.image} alt={character.name} />
            </div>
            <div className='char-info'>
                <p>Code: <strong>{character.code}</strong></p>
            </div>
        </div>
    );
}