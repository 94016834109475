import React from 'react';
import PagesShow from './Pages';
import CharShow from './Characters';
export default function MainLayout({ leftChildren, imageSrc, imageAlt, videoSrc, centered, flat, charShow }) {
    return (
        <>
            {
                !charShow ? <PagesShow
                    imageSrc={imageSrc}
                    imageAlt={imageAlt}
                    leftChildren={leftChildren}
                    videoSrc={videoSrc}
                    centered={centered}
                /> : <CharShow
                    charShow={charShow}
                    flat={flat}
                />
            }
        </>
    );
}