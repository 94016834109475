import React from 'react';
import MainLayout from 'Screens/components/MainLayout';
import MainPara from 'Screens/components/MainPara';
import HomeSocial from 'Screens/components/HomeSocial';
// -- image
import Queen from "Utils/Assets/Images/Characters/Char1.jpg";
// -- image
export default function HomeIndex() {
    return (
        <MainLayout
            centered
            leftChildren={
                <>
                    <MainPara title={'welcome'} text={'welcomeText'} />
                    <HomeSocial />
                </>
            }
            imageSrc={Queen}
            imageAlt={'HomePageImage'}
        />
    );
}