import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
export default function MainPara({title, text, social, CharCode, charType}) {
    const { t } = useTranslation();
    const htmlDecode = (text) => {
        const e = document.createElement('p');
        if(CharCode) e.innerHTML = text.replaceAll('@P1', CharCode).replaceAll('[','<span>').replaceAll(']','</span>');
        else e.innerHTML = text.replaceAll('@BR','</br>').replaceAll('[','<span>').replaceAll(']','</span>');
        return e.childNodes.length === 0 ? "" : e.innerHTML;
    }
    const htmlDecode_text = (text) => {
        const e = document.createElement('p');
        e.innerHTML = text.replaceAll('@BR','</br>');
        return e.childNodes.length === 0 ? "" : e.innerHTML;
    }
    return (
        <div className={`para-main ${charType ? 'char-type' : ''}`}>
            <h2 className='title' dangerouslySetInnerHTML={{ __html: htmlDecode(t(title))}}/>
            <div dangerouslySetInnerHTML={{ __html: htmlDecode_text(t(text))}}/>
            {/* <p>{t(text)}</p> */}
            {
                social && 
                <div className='social'>
                    {
                        social.map((i, index) => {
                            return <Link 
                                key={'contactus-social' + index}
                                to={i.link}
                                target="_blank">{i.icon}
                            </Link>
                        })
                    }
                </div>
            }
        </div>
    );
}