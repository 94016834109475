import React, { useEffect, useState } from 'react';
import MainLayout from 'Screens/components/MainLayout';
import AllCharShow from './components/AllCharShow';
import OneCharShow from './components/OneCharShow';
import { move2Top, setLoaderDelay } from 'Utils/Helpers/ApiServices';
import { useDispatch } from 'react-redux';
import Dialog from 'Screens/components/Dialog';
export default function CharactersIndex() {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [selected, setSelected] = useState(null);
    const selectcharacter = (character) => { setShow(true); setSelected(character); }
    const BackToCollection = () => { setSelected(null); }
    useEffect(() => {
        move2Top();
        dispatch(setLoaderDelay());
    },[selected])
    return (
        <>
            <MainLayout
                flat
                charShow={
                    !selected ?
                    <AllCharShow
                        show={show}
                        selected={selected}
                        onClick={item => selectcharacter(item)}
                    /> : 
                    <OneCharShow
                        selected={selected}
                        onClick={BackToCollection}
                    />
                }
            />
            <Dialog />
        </>
    );
}