import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Layout from './components/Layout';
import HomeIndex from './Home/HomeIndex';
import TamimaIndex from './Tamima/TamimaIndex';
import CharactersIndex from './Characters/CharactersIndex';
import ContactusIndex from './Contactus/ContactusIndex';
export default function App() {
  return (
    <Router>
      <Routes>
        <Route exact path={'/'} element={<Layout />}>
          <Route exact path="" element={<HomeIndex />} />
          <Route exact path="tamima" element={<TamimaIndex />} />
          <Route exact path="characters" element={<CharactersIndex />} />
          <Route exact path="contactus" element={<ContactusIndex />} />
        </Route>
        <Route path="*" element={<Navigate replace to="/" />} />
     </Routes>
   </Router>
  );
}