import Logo from "Utils/Assets/Images/Logo.png";
import SysLogo from './components/Logo';
import MenuSmall from './components/MenuSmall';
import MenuDrawer from './components/MenuDrawer';
export default function Menu() {
    return (
        <div className='menu-items'>
            <SysLogo
                image={Logo}
                alt='tamima'
            />
            <MenuSmall />
            <MenuDrawer />
        </div>
    );
}