import React from 'react';
import * as Icons from 'react-bootstrap-icons';
import CharShow from './CharShow';
import IButton from 'Screens/components/iButton';
import SubCharShow from './SubCharShow';
import MainPara from 'Screens/components/MainPara';
import { useTranslation } from 'react-i18next';
export default function OneCharShow({ selected, onClick }) {
    const { t } = useTranslation();
    return (
        <div className={`base on-${selected ? 'show' : 'hide'}-mode-one`}>
            {selected && <>
                <IButton
                    label={'Back'}
                    icon={<Icons.CaretLeftFill />}
                    onClick={onClick}
                    className={'fixedWidth'}
                />
                <div className='data-show'>
                    <div className='w-100 d-block d-lg-none'>
                        <MainPara
                            title={'charactersCodeShow'}
                            text={t(selected.description)}
                            CharCode={selected.code}
                            charType
                        />
                    </div>
                    <div className='image'>
                        <CharShow
                            character={selected}
                            fixed
                        />
                    </div>
                    <div className='infos'>
                        <div className='w-100 d-none d-lg-block'>
                            <MainPara
                                title={'charactersCodeShow'}
                                text={t(selected.description)}
                                CharCode={selected.code}
                                charType
                            />
                        </div>
                        {
                            selected.other.length !== 0 &&
                            <div className='more'>
                                <MainPara
                                    title={'otherShapeOfCharacter'}
                                    charType
                                />
                                <div className='images'>
                                    {selected.other.map(sub => { return (
                                        <SubCharShow key={'SubCharacter-' + sub.id} character={sub} />
                                    )})}
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </>}
        </div>
    )
}