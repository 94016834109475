export const enTexts = {
    welcome: "In the [Galaxy's] Heart",
    welcomeText: "In the galaxy, at the point of origin, where divine secrets lie hidden, where the absolute magnitude of great energy resides, a gate opened. This gate, as vast as divine grandeur, had seven doors. These doors, crafted from dreams, love, and uniqueness, were the threshold to a realm inhabited by extraordinary creatures brimming with astonishing intelligence and talent. Each creature, more intelligent than the last, possessed unique colors, faces, and divine gifts full of excitement and vitality. They exhibited a wide range of emotions, from anger to calm, from tears to laughter, all controlled within their minds, making them disciplined judges.",
    homeSecondParaTitle: 'Our [Story]',
    homeSecondParaText: 'Lorem Ipsum is simply dummy text of the printing and  typesetting industry. Lorem Ipsum is simply dummy text of the printing and  typesetting industry.',

    charactersPageTitle: 'My [Characters]',
    charactersPageText: "Each legend you see here is a testament to the boundless realms of imagination, meticulously hand-drawn with heart and soul, capturing the whispers of a universe beyond our own. These artworks are not just creations; they are living stories, hand-crafted to bring the mystical and the extraordinary into our world.",
    charactersCodeShow: 'Characters Code [@P1]',
    charactersCodeText: 'Lorem Ipsum is simply dummy text of the printing and  typesetting industry.',
    otherShapeOfCharacter: 'Other [Character]',
    character_1_description: "The queen is a uniquely beautiful being with extraordinary eyes that see beyond the ordinary. Her gaze is filled with love, but when justice calls, her eyes turn fiery, restoring balance with a mere glance. Her eyelashes carry the scent of spring and vitality, a divine essence wherever she is. This heavenly aroma is unlike anything ever experienced. Her ears can hear even the faintest whispers in the galaxy, processing them through six underlying layers before she decides whether to speak. Her mind transcends time, understanding the need for a meaningful life. Her body, made from pure divine silk, carries powers she uses wisely and gracefully.",
    character_2_description: "Due to its high capabilities, it is responsible for communication with the creative forces of other planets.@BRWith its mouth and the symbols on its face, it establishes connections with other planets. Through its ears, it listens to their messages and assesses whether these other planets have the ability to communicate with the planet Tamima. If they do, a connection with Tamima is established, utilizing their creativity for the benefit of Tamima.@BRFurthermore, thanks to its remarkable symbols and the high energy within its body, it is able to almost control the rotation of the planet Tamima.",
    character_3_description: "Blue Legends",

    tamimaPageTitle: 'The Beginning of a [Mystical] Journey',
    tamimaPageText: 'Everything started on the day I was meditating to calm my mind and body... I had my eyes closed and drifted into an amazing dream. In this dream, I saw a planet full of extraordinary creatures, all looking at me with deep and meaningful eyes, carrying an unspoken desire. I felt that my soul, thanks to the tranquility I had found, had entered their world. Seeing them ignited a burning passion in me to depict them through my art. And I began to draw...',
    tamimaSecondParaTitle: 'Guided by [Unseen] Forces',
    tamimaSecondParaText: 'With the first line drawn on the paper, my conscious mind seemed to switch off, and my subconscious took over. Time stopped; I no longer heard the sounds around me and all my senses were immersed in another world. While drawing these extraordinary creatures, I heard their voices in my mind, each speaking in a friendly and mysterious tone, urging me to bring them into our world. A powerful force connected to my brain, guiding my hands. As I drew, the voice of each creature whispered in my mind, guiding me on how to capture their image beautifully. They guided every line and curve, telling me exactly how my hand should move.',
    tamimaThirdParaTitle: 'Creation Beyond [Time] and [Space]',
    tamimaThirdParaText: 'Choosing colors became an intimate conversation; they told me which colors best represented them. Some drawings took shape easily in a few minutes, while others took hours, each line and color guided by the voices. Throughout this time, I worked in silence, listening only to the instructions given to me. In the process of drawing, they described their appearance to me and told me what details to add, including inscriptions on their bodies and the numbers used in them, all through the voices in my mind. After completing each design, I felt a sense of fatigue similar to after hours of practice, tired but enthusiastic, as a lot of energy had been put into each work. When I look at my works, I often find myself in wonder as I don’t remember some details, as these paintings originate entirely from my subconscious, without the influence of my conscious mind. I am deeply grateful for this unique gift – the ability to depict these creatures and share them with the world. I am certain that these creatures exist somewhere in the galaxy and are eager to be seen. Perhaps in the near future, we can see these creatures in the real world.',
    
    contactusTitle: '[Contact] us',
    contactusText: 'through the following ways',
    emailTitle: 'Direct [Contact]',
    emailPlaceHolder: 'Type something ...',
    emailSend: 'Send',
    successEmail: 'Email sent successfully',
    successEmailThank: 'Thank you for your participation',

    sub_info_4466: "A creature without the power of speech communicates through its actions. It detects lies through the yellow dots on its body and responds using red beams from its body. If angered, the beam turns redder, while in calm states, the beam softens.",
    sub_info_3333: "This creature has a circular mark on its head that absorbs negative energy from the planet and neutralizes it.",
    sub_info_2222: "With a special power located in its mouth, this being prevents the overgrowth of trees on the planet Tamima, pruning them to maintain balance.",
    sub_info_1133: "If any unwanted plants try to grow, this creature uses its eyelashes to dry them up, transforming them into soil similar to Tamima's.",
    sub_info_3322: "It possesses a remarkable sense of smell, capable of detecting and repelling harmful insects attempting to invade Tamima from other planets.",
    sub_info_8899: "With its eyes and eyelashes, it fertilizes and tills Tamima's lands, preparing them to grow a special type of plant that protects the planet.",
    sub_info_7788: "Its face, resembling ears, gives it excellent hearing. It catches even the slightest sounds and sends them to the queen for analysis using its eyelashes. (One of the queen’s abilities is to understand the true meaning behind words.)",
    sub_info_6677: "It absorbs all the purple light of Tamima, categorizes it using its eyelashes, and distributes the light to areas needing energy.",
    sub_info_5566 : "Its head acts as a radar, capable of examining strange rays or lights. It directs these rays to the planet's left side, neutralizing any potential threats.",
    sub_info_111: "Its duty is to review Tamima's documents, stamping them for approval with its eyelashes before sending them to the queen.",
    sub_info_4444: "This being is highly aware and thoughtful about matters. It speaks wisely, carefully pausing before responding to anything it hears. It does not speak on a subject unless it has seen it for itself.",
    sub_info_9999: "Due to the numbers on its face, it is responsible for calculating the number of meteorites and the distances between planets.",
    sub_info_4455: "With its red-colored ears and eyes, it has heightened vision and hearing. It can detect tiny insects under Tamima's soil and uses the symbol on its head to transform these insects into helpful and beneficial creatures for the planet.",
    sub_info_8888: "This creature specializes in identifying any stranger attempting to enter Tamima. It has the ability to recognize individuals who have changed their appearance.",
    sub_info_7777: "Because of its uniquely shaped head and forehead, it functions like a powerful camera or telescope, capable of observing distances far beyond Tamima for miles.",
    sub_info_3344: "With its multiple eyes and large ear, it possesses excellent lip-reading and hearing abilities. It can understand the languages of all other planets using its eyes, analyze them with its ear, and alert the queen if any danger is detected from other beings.",
    sub_info_6666: "There are five red spots on its face and body, which sound an alarm if anyone tries to leave Tamima without permission. Its eyelashes quickly change shape to detect the direction the person is leaving from and prevent them from leaving the planet.",
    sub_info_5555: "Its head can detach from its body. During separation, both its head and body remain alive, allowing it to be present in two places at once if needed.",
    sub_info_2233: "With the geometric and mathematical shapes in its eyelashes and body, it has the power to change certain homes on Tamima that need alterations.",
    sub_info_333: "With its kind facial expression, it can transform sadness into joy if it detects sorrow in any of Tamima’s inhabitants. Its blue face brings peace to them.",
    sub_info_1122: "This creature’s ability to create a rainbow, due to its mismatched eyes and an unusual piece on the back of its head, is truly remarkable. By spinning its eyes, it forms a rainbow with three colors: blue, yellow, and gray. Unlike Earth’s rainbow, this circular rainbow enhances the mysterious beauty of Tamima.",
}