import * as Icons from 'react-bootstrap-icons';
import OpenSee from '../Assets/Images/opensee.svg'

export const Social = [
    {
        id: 1,
        name: 'TwitterX',
        icon: <Icons.TwitterX />,
        link: 'https://x.com/the_tamima'
    },{
        id: 2,
        name: 'EMail',
        icon: <Icons.EnvelopePaper />,
        link: 'mailto:tamimaplanet@gmail.com'
    },{
        id: 3,
        name: 'OpenSee',
        icon: <img src={OpenSee}/>,
        link: 'https://opensea.io/The_Tamima'
    },
]