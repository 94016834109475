import { Social } from 'Utils/Constants/Social';
import { Link } from 'react-router-dom';
export default function HomeSocial() {
    return (
        <div className='social-home'>
            <div className='social inner-home'>
                {Social.map((i, index) => {
                    return <Link
                        key={'home-social' + index}
                        to={i.link}
                        target="_blank">{i.icon}
                    </Link>
                })}
            </div>
        </div>
    );
}