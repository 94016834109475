import { useNavigate } from 'react-router-dom';
import { MenuItems } from 'Utils/Constants/MenuItems';
import { move2Top } from 'Utils/Helpers/ApiServices';
export default function MenuDrawer({ onClick }) {
    const navigate = useNavigate();
    const handlePush = (item) => {
        navigate(item.action);
        move2Top();
        try{ onClick() } catch {}
    }
    return (
        <ul>
            {
                MenuItems.filter(item => item.active).map(item => {
                    return (
                        <li
                            key={`menuItems-${item.id}`}
                            onClick={() => handlePush(item)}
                            className={window.location.pathname === item.action ? 'active' : ''}
                        >
                            <p className='text-nowrap'>
                                {item.title}
                            </p>
                        </li>
                    )
                })
            }
        </ul>
    );
}