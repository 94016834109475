import { createSlice } from '@reduxjs/toolkit';

const settingsSlice = createSlice({
    name : 'setting',
    initialState : {
        loading: true,
        dialog: {
            open: false,
            title: '',
            image: '',
            content: '',
        }
    },
    reducers : {
        setLoading: (state , action) => {
            state.loading = action.payload;
        },
        setOpenDialog: (state , action) => {
            state.dialog = {
                open: true,
                title: action.payload.title,
                image: action.payload.image,
                content: action.payload.content
            };
        },
        setCloseDialog: (state , action) => {
            state.dialog = {
                open: false,
                title: '',
                image: '',
                content: ''
            };
        },
    }
})

export const { 
    setLoading,
    setOpenDialog,
    setCloseDialog,
} = settingsSlice.actions;

export default settingsSlice.reducer;