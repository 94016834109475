// -- images
import Char1 from 'Utils/Assets/Images/Characters/Char1.webp';
import Char2 from 'Utils/Assets/Images/Characters/Char2.webp';
import Char21 from 'Utils/Assets/Images/Characters/Char2-1.webp';
import Char22 from 'Utils/Assets/Images/Characters/Char2-2.webp';
import Char23 from 'Utils/Assets/Images/Characters/Char2-3.webp';
import Char24 from 'Utils/Assets/Images/Characters/Char2-4.webp';
import Char25 from 'Utils/Assets/Images/Characters/Char2-5.webp';
import Char26 from 'Utils/Assets/Images/Characters/Char2-6.webp';
import Char27 from 'Utils/Assets/Images/Characters/Char2-7.webp';
import Char28 from 'Utils/Assets/Images/Characters/Char2-8.webp';
import Char29 from 'Utils/Assets/Images/Characters/Char2-9.webp';
import Char210 from 'Utils/Assets/Images/Characters/Char2-10.webp';
import Char211 from 'Utils/Assets/Images/Characters/Char2-11.webp';
import Char212 from 'Utils/Assets/Images/Characters/Char2-12.webp';
import Char213 from 'Utils/Assets/Images/Characters/Char2-13.webp';
import Char214 from 'Utils/Assets/Images/Characters/Char2-14.webp';
import Char215 from 'Utils/Assets/Images/Characters/Char2-15.webp';
import Char216 from 'Utils/Assets/Images/Characters/Char2-16.webp';
import Char217 from 'Utils/Assets/Images/Characters/Char2-17.webp';
import Char218 from 'Utils/Assets/Images/Characters/Char2-18.webp';
import Char219 from 'Utils/Assets/Images/Characters/Char2-19.webp';
import Char3 from 'Utils/Assets/Images/Characters/Char3.webp';
import Char31 from 'Utils/Assets/Images/Characters/Char3-1.webp';
import Char32 from 'Utils/Assets/Images/Characters/Char3-2.webp';
// -- images

export const Characters = [
    {
        id: 1,
        code: '5555555',
        image: Char1,
        description: 'character_1_description',
        other: []
    },{
        id: 2,
        code: '1111',
        image: Char2,
        description: 'character_2_description',
        other: [
            {
                id: 201,
                code: '2222',
                image: Char21,
                more: 'sub_info_2222',
            },{
                id: 202,
                code: '3333',
                image: Char22,
                more: 'sub_info_3333',
            },{
                id: 203,
                code: '4444',
                image: Char23,
                more: 'sub_info_4444',
            },{
                id: 204,
                code: '5555',
                image: Char24,
                more: 'sub_info_5555',
            },{
                id: 205,
                code: '6666',
                image: Char25,
                more: 'sub_info_6666',
            },{
                id: 206,
                code: '7777',
                image: Char26,
                more: 'sub_info_7777',
            },{
                id: 207,
                code: '8888',
                image: Char27,
                more: 'sub_info_8888',
            },{
                id: 208,
                code: '9999',
                image: Char28,
                more: 'sub_info_9999',
            },{
                id: 209,
                code: '1122',
                image: Char29,
                more: 'sub_info_1122',
            },{
                id: 210,
                code: '2233',
                image: Char210,
                more: 'sub_info_2233',
            },{
                id: 211,
                code: '3344',
                image: Char211,
                more: 'sub_info_3344',
            },{
                id: 212,
                code: '4455',
                image: Char212,
                more: 'sub_info_4455',
            },{
                id: 213,
                code: '5566',
                image: Char213,
                more: 'sub_info_5566',
            },{
                id: 214,
                code: '6677',
                image: Char214,
                more: 'sub_info_6677',
            },{
                id: 215,
                code: '7788',
                image: Char215,
                more: 'sub_info_7788',
            },{
                id: 216,
                code: '8899',
                image: Char216,
                more: 'sub_info_8899',
            },{
                id: 217,
                code: '3322',
                image: Char217,
                more: 'sub_info_3322',
            },{
                id: 218,
                code: '1133',
                image: Char218,
                more: 'sub_info_1133',
            },{
                id: 219,
                code: '4466',
                image: Char219,
                more: 'sub_info_4466',
            },{
                id: 220,
                code: '111',
                image: Char31,
                more: 'sub_info_111',
            },{
                id: 221,
                code: '333',
                image: Char32,
                more: 'sub_info_333',
            },
        ]
    },{
        id: 3,
        code: '222',
        image: Char3,
        description: 'character_3_description',
        other: []
    },
]