import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { enTexts } from "./locales/EN";
import { frTexts } from "./locales/FR";
import { itTexts } from "./locales/IT";
import { readLanguage } from "Utils/Helpers/LocalStorage";
i18n.use(initReactI18next).init({
    lng: readLanguage() ? readLanguage() : 'en',
    fallbackLng: "en",
    interpolation: { escapeValue: false },
    resources:{
        en: { translation: enTexts },
        fr: { translation: frTexts },
        it: { translation: itTexts },
    },
});
export default i18n;