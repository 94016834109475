import axios from "axios";
import { setLoading } from "../../Redux/slices/settingsSlice";
import { storeLanguage } from "./LocalStorage";
import i18n from "../../Utils/Languages/i18n";
const _baseURL = process.env.REACT_APP_BACKEND_BASE_URL;
export const firstinit = () => {
    return (dispatch) => {
        setTimeout(() => {
            dispatch(setLoading(false));
        }, 1500);
    }
};
export const setLoaderDelay = () => {
    return (dispatch) => {
        dispatch(setLoading(true));
        setTimeout(() => {
            dispatch(setLoading(false));
        }, 500);
    }
};
export const setLoader = (status) => {
    return (dispatch) => {
        dispatch(setLoading(status))
    }
};
export const move2Top = () => {
    try {
        setTimeout(() => {
            document.getElementById('paperbody').scrollTo({ top: 0 });
            window.scrollTo({ top: 0 });
        }, 100);
    } catch {}
};
export const changeLanguage = (language) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        storeLanguage(language);
        setTimeout(() => {
            i18n.changeLanguage(language);
            dispatch(setLoading(false));
            return true;
        }, 1000);
    }
}

export const sendEmail = async (data) => {
    try {
        const result = await axios.post(_baseURL, data);
        return result;
    } catch {
        return false;
    }
}
export const checkMailer = async (data) => {
    try {
        let err = {};
        if(data.email === "") {
            err = {mode: 1, text: '** Email is required **'};
            return { status: false, err };
        } else if(!validateEmail(data.email)) {
            err = {mode: 1, text: '** Email is not valid **'};
            return { status: false, err };
        } else if(data.message === "") {
            err = {mode: 2, text: '** Message is required **'};
            return { status: false, err };
        } else if(data.message.length < 5) {
            err = {mode: 2, text: '** Message is too short **'};
            return { status: false, err };
        }
        const result = await sendEmail({ email: data.email, message: data.message });
        if(result && result.data.ok) return { status: true, err: { mode: 0, text: ''} };
        else return { status: true, err: { mode: 2, text: 'Email is not send, Please try again later.' } };
    } catch {
        return { status: false, err: { mode: 2, text: 'Email is not send. Something went wrong!' } };
    }
}
const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
}