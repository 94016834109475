import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Icons from 'react-bootstrap-icons';
import IButton from './iButton';
import { checkMailer, setLoader } from 'Utils/Helpers/ApiServices';
import { useDispatch } from 'react-redux';
export default function EmailSender() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [error, setError] = useState({mode: 0, text: ''});
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [send, setSend] = useState(false);
    const htmlDecode = (text) => {
        const e = document.createElement('p');
        e.innerHTML = text.replaceAll('@BR','</br>').replaceAll('[','<span>').replaceAll(']','</span>');
        return e.childNodes.length === 0 ? "" : e.innerHTML;
    };
    const sendMail = async () => {
        dispatch(setLoader(true));
        const res = await checkMailer({email, message});
        if(res.status) {
            setEmail('');
            setMessage('');
            setError({mode: 0, text: ''});
            setSend(true);
            setTimeout(() => {
                setSend(false);
            }, 5000);
        } else setError(res.err);
        dispatch(setLoader(false));
    };
    useEffect(() => {
        if(error.mode !== 0) setTimeout(() => { setError({mode: 0, text: ''}) }, 5000);
    },[error])
    return (
        <div className='email-sender'>
            {
                send &&
                <div key={'emailSendNotice'} className={`send-success ${send ? 'show' : 'hide'}-mode`}>
                    <p>{t('successEmail')}</p>
                    <p>{t('successEmailThank')}</p>
                </div>
            }
            <h2 className='title' dangerouslySetInnerHTML={{ __html: htmlDecode(t('emailTitle'))}}/>
            <input
              id="email"
              type="email"
              name="email"
              aria-label='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={"Email"}
            />
            {error.mode === 1 && <small className='w-100 text-danger px-4'>{error.text}</small>}
            <textarea
              id="message"
              name="message"
              aria-label='message'
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder={t('emailPlaceHolder')}
            />
            {error.mode === 2 && <small className='w-100 text-danger px-4'>{error.text}</small>}
            <IButton
                label={t('emailSend')}
                icon={<Icons.EnvelopeArrowUp />}
                onClick={sendMail}
                className={'fullWidth'}
            />
        </div>
    );
}