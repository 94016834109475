export const MenuItems = [
    {
        id: 1,
        title: "Home",
        action: '/',
        active: true,
    },{
        id: 2,
        title: "Tamima",
        action: '/tamima',
        active: true,
    },{
        id: 3,
        title: "Characters",
        action: '/characters',
        active: true,
    },{
        id: 4,
        title: "Contact us",
        action: '/contactus',
        active: true,
    },
]