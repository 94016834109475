import React from 'react';
import ImageBox from './ImageBox';
export default function PagesShow({ imageSrc, imageAlt, videoSrc, centered, leftChildren }) {
    return (
        <>
            {
                imageSrc && <div className='side image'>
                    <ImageBox image={imageSrc} alt={imageAlt} />
                </div>
            }{
                videoSrc && <div className='side video'>
                    <video autoPlay loop className='video-box'>
                        <source src={videoSrc} type='video/webm'/>
                    </video>
                </div>
            }
            <div className={`side data ${centered ? 'centered' : ''}`}>{leftChildren}</div>
        </>
    )
}